@import "./assets/icon-font/iconfont.css";

html,
body,
#app {
  width: 100%;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input,
textarea {
  -webkit-user-select: auto;
}

li {
  list-style: none;
}

.view_box {
  height: calc(100% - 1.28rem);
  overflow: auto;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.28rem;
  background: #fff;
  box-shadow: 0 -0.026667rem 0 0 #e6eaf0;
  display: flex;
  justify-content: space-between;
  padding: 0 0.4rem;
}

footer div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #cacad2;
}

footer .fot_name {
  margin-top: 0.053333rem;
  font-size: 0.266667rem;
  font-family: PingFangSC-Medium,PingFang SC;
  font-weight: 500;
  line-height: 0.373333rem;
}

footer .set_font_color {
  color: #29bebc;
}

.apostrophe {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}