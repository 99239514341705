.form_box .van-cell {
  padding-left: 0;
  padding-right: 0;
}

.form_box .van-button--round {
  width: 9.146667rem;
  height: 1.173333rem;
  background: #29bebc;
  border-radius: 0.133333rem;
  border: none;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.form_box .van-cell::after {
  left: 0;
  right: 0;
}

.form_box .van-field--error .van-field__control,
.form_box .van-field--error .van-field__control::placeholder {
  color: #999;
}