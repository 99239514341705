.not_pass {
  padding-top: 1.866667rem;
}

.not_pass .not_p_img {
  width: 1.813333rem;
  height: 1.813333rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.not_pass h1 {
  text-align: center;
  font-size: 0.48rem;
  font-family: PingFangSC-Medium,PingFang SC;
  font-weight: 500;
  color: #353535;
  padding-top: 0.533333rem;
  padding-bottom: 1.653333rem;
}

.not_pass h6 {
  font-size: 0.373333rem;
  font-family: PingFang-SC-Medium,PingFang-SC;
  font-weight: 500;
  color: #353535;
  text-align: center;
}