.form_box {
  padding: 1.066667rem 0.72rem 0 0.72rem;
}

.form_box .set_pad_top {
  padding-top: 1.44rem;
  display: inline-block;
}

.form_box .remember_password {
  font-size: 0.373333rem;
  font-family: PingFang-SC-Medium,PingFang-SC;
  font-weight: 500;
  color: #353535;
  display: flex;
  align-items: center;
  padding-top: 0.506667rem;
  padding-bottom: 1.333333rem;
}

.form_box .remember_password span {
  display: inline-block;
  width: 0.373333rem;
  height: 0.373333rem;
  border: 0.026667rem solid #efeff4;
  margin-right: 0.213333rem;
}

.form_box .remember_password .span_bgi {
  background-image: url("../../assets/img/radio-sel.png");
  border: none;
  background-size: 100% 100%;
}

.lable_type {
  font-size: 0.426667rem;
  font-family: NotoSansHans-Regular,NotoSansHans;
  font-weight: 400;
  color: #303235;
}