.content_box ul {
  height: 100%;
  overflow: auto;
}

.content_box li {
  display: flex;
  font-size: 0.4rem;
  font-family: PingFangSC-Medium,PingFang SC;
  font-weight: 500;
  color: #353535;
  padding: 0.266667rem 0.533333rem;
}

.content_box li em {
  font-style: normal;
}

.content_box li span {
  color: #888;
  width: 6.933333rem;
  margin-left: 0.4rem;
  font-size: 0.373333rem;
  margin-top: 0.053333rem;
}

.content_box .btn {
  width: 90%;
  margin: 0 auto;
  display: block;
  line-height: 0.8rem;
  font-size: 0.4rem;
  margin-top: 0.266667rem;
}