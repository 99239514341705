.pass_audit {
  padding-top: 0.613333rem;
  padding-bottom: 1.333333rem;
}

.pass_audit .get_through {
  font-size: 0.4rem;
  font-family: PingFangSC-Medium,PingFang SC;
  font-weight: 500;
  color: #353535;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.693333rem;
}

.pass_audit .get_through img {
  width: 0.64rem;
  height: 0.64rem;
  margin-right: 0.293333rem;
}

.pass_audit .qr_code {
  width: 4.213333rem;
  height: 4.213333rem;
  margin-left: 50%;
  transform: translateX(-50%);
}

.pass_audit .period_validity {
  margin-top: 0.346667rem;
  display: flex;
  padding: 0 0.986667rem 0 1.28rem;
}

.pass_audit .period_validity span {
  font-size: 0.373333rem;
  font-family: PingFang-SC-Medium,PingFang-SC;
  font-weight: 500;
  color: #8f8f8f;
  line-height: 0.533333rem;
}

.pass_audit .period_validity .period_label {
  display: inline-block;
  width: 2.133333rem;
}

.pass_audit .explain {
  font-size: 0.4rem;
  font-family: PingFang-SC-Medium,PingFang-SC;
  font-weight: 500;
  color: #353535;
  line-height: 0.56rem;
  padding: 0.586667rem 0.986667rem 0 1.013333rem;
}

.pass_audit .refresh {
  font-size: 0.373333rem;
  font-family: PingFang-SC-Medium,PingFang-SC;
  font-weight: 500;
  color: #29bebc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.64rem;
}

.pass_audit .refresh img {
  width: 0.373333rem;
  height: 0.373333rem;
  margin-right: 0.213333rem;
}

.pass_audit .equip_box::before {
  content: "";
  display: inline-block;
  width: 100%;
  height: 0.266667rem;
  background-color: #f4f5f9;
  margin-top: 0.613333rem;
}

.pass_audit .equip_box {
  padding-bottom: 0.533333rem;
}

.pass_audit .equip_box .equip_name {
  display: inline-block;
  width: 2.666667rem;
}

.pass_audit .equip_box h1 {
  padding: 0.693333rem 0.426667rem 0 0.426667rem;
  font-size: 0.426667rem;
  font-family: PingFangSC-Medium,PingFang SC;
  font-weight: 500;
  color: #353535;
  line-height: 0.586667rem;
}

.pass_audit .equip_box ul {
  margin-top: 0.773333rem;
}

.pass_audit .equip_box ul li {
  display: flex;
  align-items: flex-start;
  font-size: 0.426667rem;
  font-family: NotoSansHans-Regular,NotoSansHans;
  font-weight: 400;
  color: #303235;
  margin-bottom: 1.04rem;
}

.pass_audit .equip_box ul li span {
  padding: 0 0 0 0.853333rem;
  display: inline-block;
  width: 2.666667rem;
  font-size: 0.373333rem;
  font-family: PingFang-SC-Medium,PingFang-SC;
  font-weight: 500;
  color: #353535;
}

.pass_audit .equip_box ul li p {
  flex: 1;
  padding: 0 0.853333rem 0 0.266667rem;
  font-size: 0.373333rem;
  font-family: PingFang-SC-Medium,PingFang-SC;
  font-weight: 500;
  color: #888;
  line-height: 0.533333rem;
}

.pass_audit .equip_box ul li .reminder {
  color: #29bebc;
}

.close_page {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.333333rem;
  background-color: #fff;
}